import logo from "./logo.webp"

const  clientData = {
   client_entity: 35,
   attorney_firm: 'dorothybutlerlawfirm.com',
    attorney_name: 'Dorothy Butler',
    attorney_number: '512-699-5632',
    attorney_email: 'Dorothy@dorothybutlerlawfirm.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_dorothy_butler_law+(720p).mp4',
    mainColor: ' #322C48',
    secondaryColor: '#7E8395',
    siteLink: 'http://www.dorothybutlerlawfirm.com',
    logo
}

export default clientData